import { HTTP } from '../providers'
import { RouletteOptions } from '../types/roulette'

export class RouletteOptionsService extends HTTP {
	async getOptionsRoulette(): Promise<RouletteOptions[]> {
		const options = await this.get({
			endpoint: '/roulette-options'
		})
		return options.data as RouletteOptions[]
	}

	async getOptionsRouletteQuery(appId: string): Promise<RouletteOptions[]> {
		const options = await this.get({
			endpoint: `/roulette-options/query?appId=${appId}`
		})
		return options.data as RouletteOptions[]
	}

	async saveOptionsRoulette(
		params: Partial<RouletteOptions>[]
	): Promise<RouletteOptions> {
		const options = await this.post({
			endpoint: '/roulette-options',
			data: params
		})
		return options.data as RouletteOptions
	}
}
