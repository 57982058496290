import { RouletteOptionsService } from '../services/rouletter-options'
import { RouletteOptions } from '../types/roulette'
import { moneyToNumber } from '../utils/money-to-number'

export const getOptionsRouletteAction = async (): Promise<
	RouletteOptions[]
> => {
	const service = new RouletteOptionsService()
	const response = await service.getOptionsRoulette()
	return response
}

export const getOptionsRouletteQueryAction = async (
	appId: string
): Promise<RouletteOptions[]> => {
	const service = new RouletteOptionsService()
	const response = await service.getOptionsRouletteQuery(appId)
	return response
}

export const saveOptionsRouletteAction = async (
	params: Partial<RouletteOptions>[]
): Promise<RouletteOptions> => {
	const service = new RouletteOptionsService()
	const response = await service.saveOptionsRoulette(
		params.map(item => ({ ...item, value: String(item.value || '0') }))
	)
	return response
}
