import { TextField, TextFieldProps } from '@mui/material'
import { forwardRef } from 'react'
import { NumericFormat, NumericFormatProps } from 'react-number-format'

interface CustomProps {
	onChange: (event: { target: { name: string; value: string } }) => void
	name: string
}

export const NumericFormatCustom = forwardRef<NumericFormatProps, CustomProps>(
	(props, ref) => {
		const { onChange, ...other } = props

		return (
			<NumericFormat
				{...other}
				getInputRef={ref}
				onValueChange={values => {
					onChange({
						target: {
							name: props.name,
							value: values.value
						}
					})
				}}
				thousandSeparator="."
				decimalSeparator=","
				valueIsNumericString
				prefix="R$ "
			/>
		)
	}
)

function MoneyField(props: TextFieldProps) {
	return (
		<TextField
			{...props}
			InputProps={{
				inputComponent: NumericFormatCustom as any
			}}
		/>
	)
}

export default MoneyField
