import { format } from 'date-fns'

const mapperMonth: Record<number, string> = {
	1: 'Janeiro',
	2: 'Fevereiro',
	3: 'Março',
	4: 'Abril',
	5: 'Maio',
	6: 'Junho',
	7: 'Julho',
	8: 'Agosto',
	9: 'Setembro',
	10: 'Outubro',
	11: 'Novembro',
	12: 'Dezembro'
}

export const formatDatePtBr = (date: Date) => format(date, 'dd/MM/yyyy')

export const formatDateHourPtBr = (date: Date) =>
	format(date, 'dd/MM/yyyy HH:mm:ss')

export const monthToString = (date: Date) => {
	const month = date.getMonth() + 1
	if (!mapperMonth[month]) return ''
	return mapperMonth[month]
}

export const dateStringToPtBr = (date: string) => {
	const [year, month, day] = date.split('-')
	return `${day}/${month}/${year}`
}
