import { Box } from '@material-ui/core'
import { Button, createTheme, Grid, TextField, Typography } from '@mui/material'
import { ThemeProvider } from '@mui/styles'
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom'
import React, { useCallback, useState } from 'react'
import { AppCodeNotFound, LinearProgressFidelizap } from '../../components'
import { NumericFormatCustom } from '../../components/money-field'
import { AppLogo } from '../../components/logo-application'

const defaultTheme = createTheme()

function ClientDataValue() {
	const [searchParams, _setSearchParams] = useSearchParams()
	const appCode = searchParams.get('app_code')
	const cellphone = searchParams.get('cell')
	const location = useLocation()
	const navigate = useNavigate()
	const [isValidCode, setIsValidCode] = useState<boolean>(true)
	const [value, setValue] = useState<string>('')
	const [erroValue, setErroValue] = useState<boolean>(false)
	const { state: { imagePathState, name, birthDate } = {} } = location

	const [imagePath, setImagePath] = useState<string | undefined>(imagePathState)

	const goToSendRequest = useCallback(() => {
		const numValue = Number(value.replace(/[^0-9]/g, ''))
		if (numValue <= 0) {
			setErroValue(true)
			return
		}
		const appCodeEncoded = encodeURIComponent(String(appCode))
		const cellphoneEncoded = encodeURIComponent(cellphone || '')
		navigate(
			`/client/loading?app_code=${appCodeEncoded}&cell=${cellphoneEncoded}`,
			{
				state: {
					imagePathState: imagePath,
					name,
					birthDate,
					value
				}
			}
		)
	}, [appCode, navigate, imagePath, value, name, birthDate])

	if (!appCode || !isValidCode || !cellphone) {
		return (
			<AppCodeNotFound
				code={appCode || ''}
				cellphone={cellphone}
				backgroundImage="/backmobile.svg"
			/>
		)
	}

	const onChangeValue = (event: React.ChangeEvent<HTMLInputElement>) => {
		const val = event.target.value
		const numValue = Number(val)
		if (numValue < 0) {
			setValue('')
		}
		setValue(val)
	}

	return (
		<ThemeProvider theme={defaultTheme}>
			<Grid
				container
				sx={{
					height: '100vh',
					width: '100vw',
					backgroundColor: '#589ec3',
					backgroundSize: 'cover',
					backgroundPosition: 'center',
					display: 'flex',
					flexDirection: 'column',
					alignItems: 'center'
					// justifyContent: 'space-between',
				}}
			>
				<AppLogo
					code={appCode}
					setImagePath={setImagePath}
					setIsValidCode={setIsValidCode}
					imagePath={imagePath}
				/>

				{/* Perguntas */}
				<Grid
					item
					sx={{
						height: '50%',
						display: 'flex',
						width: '90%',
						flexDirection: 'column',
						alignItems: 'center',
						justifyContent: 'flex-start'
					}}
				>
					<LinearProgressFidelizap variant="determinate" value={97} />
					<Box
						sx={{
							paddingTop: 10,
							textAlign: 'center',
							maxWidth: '90%',
							marginBottom: 3
						}}
					>
						<Typography variant="h6" color="white" fontWeight="bold">
							{name}, qual valor da sua compra?
						</Typography>
					</Box>
					<TextField
						sx={{
							width: '90%',
							background: '#6cdbf4',
							borderRadius: '10px',
							input: {
								textAlign: 'center',
								color: 'white',
								padding: 2,
								fontWeight: 'bold'
							},
							borderBottom: '3px solid white'
						}}
						variant="filled"
						placeholder="R$ 0,00"
						InputProps={{
							disableUnderline: true,
							style: {
								textAlign: 'center'
							},
							inputComponent: NumericFormatCustom as any
						}}
						error={erroValue}
						helperText={erroValue ? 'Valor inválido' : ''}
						value={value}
						onChange={onChangeValue}
					/>
					<Button
						variant="contained"
						sx={{
							marginTop: 10,
							backgroundColor: '#A4FF78',
							color: 'black',
							fontWeight: 'bold',
							borderRadius: '20px',
							paddingX: 4
						}}
						onClick={goToSendRequest}
					>
						Continuar
					</Button>
				</Grid>

				{/* Logo no rodapé */}
				<Grid
					item
					sx={{
						height: '25%',
						width: '100%',
						display: 'flex',
						alignItems: 'end',
						justifyContent: 'flex-end'
					}}
				>
					<Box
						component="img"
						src="/logo.svg"
						alt="Fidelizap"
						sx={{
							width: 100,
							marginBottom: 2,
							alignSelf: 'flex-end',
							marginRight: 2
						}}
					/>
				</Grid>
			</Grid>
		</ThemeProvider>
	)
}

export default ClientDataValue
