/* eslint-disable react/no-unstable-nested-components */
import { Route, Routes } from 'react-router-dom'
import { Login, DashBoard, Panel } from '../pages'
import PrivateRoutes from './private-routes'
import Register from '../pages/register/register'
import Metrics from '../pages/panel/metrics/metrics'
import Approbation from '../pages/panel/approbation/approbation'
import Settings from '../pages/panel/settings/settings'
import Clients from '../pages/panel/clients/clients'
import ChooseScreen from '../pages/choose-screen/choose-screen'
import Plan from '../pages/panel/plans/plans'
import ClientWelcome from '../pages/client/welcome'
import ClientQuiz from '../pages/client/quiz'
import ClientDataForm from '../pages/client/data-form'
import ClientDataValue from '../pages/client/data-value'
import ClientLoading from '../pages/client/send-request'
import ClientDashBoard from '../pages/dashboard/client-dashboard'

function RoutesRoot() {
	return (
		<Routes>
			<Route path="/" Component={Login} />
			<Route path="/login" Component={Login} />
			<Route path="/register" Component={Register} />
			<Route path="/client/welcome" Component={ClientWelcome} />
			<Route path="/client/quiz" Component={ClientQuiz} />
			<Route path="/client/data-form" Component={ClientDataForm} />
			<Route path="/client/data-value" Component={ClientDataValue} />
			<Route path="/client/loading" Component={ClientLoading} />
			<Route path="/client/dashboard" Component={ClientDashBoard} />
			<Route
				path="/choose-screen"
				element={<PrivateRoutes Component={<ChooseScreen />} />}
			/>
			<Route
				path="/panel/approbation"
				element={
					<PrivateRoutes
						Component={
							<Panel itemIndex={1} title="Aprovação">
								<Approbation />
							</Panel>
						}
					/>
				}
			/>
			<Route
				path="/panel/metrics"
				element={
					<PrivateRoutes
						Component={
							<Panel itemIndex={2} title="Dashboard">
								<Metrics />
							</Panel>
						}
					/>
				}
			/>
			<Route
				path="/panel/clients"
				element={
					<PrivateRoutes
						Component={
							<Panel itemIndex={3} title="Clientes">
								<Clients />
							</Panel>
						}
					/>
				}
			/>
			<Route
				path="/panel/settings"
				element={
					<PrivateRoutes
						Component={
							<Panel itemIndex={4} title="Configurações">
								<Settings />
							</Panel>
						}
					/>
				}
			/>

			<Route
				path="/panel/plans"
				element={
					<PrivateRoutes
						Component={
							<Panel itemIndex={5} title="Assinatura">
								<Plan />
							</Panel>
						}
					/>
				}
			/>

			<Route
				path="/dashboard"
				element={<PrivateRoutes Component={<DashBoard />} />}
			/>
		</Routes>
	)
}

export default RoutesRoot
