import { Box } from '@material-ui/core'
import { Button, createTheme, Grid, TextField, Typography } from '@mui/material'
import { makeStyles, ThemeProvider } from '@mui/styles'
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom'
import { useCallback, useState } from 'react'
import { AppCodeNotFound, LinearProgressFidelizap } from '../../components'
import { AppLogo } from '../../components/logo-application'

const defaultTheme = createTheme()
const helperTextStyles = makeStyles({
	'&.MuiFormHelperText-root.Mui-error': {
		color: '#ffff'
	}
})
function ClientDataForm() {
	const [searchParams, _setSearchParams] = useSearchParams()
	const [name, setName] = useState<string>('')
	const [birthDate, setBirthDate] = useState<string>('')
	const [erroName, setErroName] = useState<boolean>(false)
	const helperText = helperTextStyles()
	const [erroBirthDate, setErroBirthDate] = useState<boolean>(false)
	const appCode = searchParams.get('app_code')
	const cellphone = searchParams.get('cell')
	const location = useLocation()
	const navigate = useNavigate()
	const [isValidCode, setIsValidCode] = useState<boolean>(true)
	const { state: { imagePathState } = {} } = location

	const [imagePath, setImagePath] = useState<string | undefined>(imagePathState)

	const goToDataValue = useCallback(() => {
		let hasError = false
		if (!name) {
			setErroName(true)
			hasError = true
		}
		if (!birthDate) {
			setErroBirthDate(true)
			hasError = true
		}
		if (hasError) {
			return
		}
		const appCodeEncoded = encodeURIComponent(String(appCode))
		const cellphoneEncoded = encodeURIComponent(cellphone || '')
		navigate(
			`/client/data-value?app_code=${appCodeEncoded}&cell=${cellphoneEncoded}`,
			{
				state: {
					name,
					birthDate,
					imagePathState: imagePath
				}
			}
		)
	}, [appCode, navigate, imagePath, name, birthDate])

	if (!appCode || !isValidCode || !cellphone) {
		return (
			<AppCodeNotFound
				code={appCode || ''}
				cellphone={cellphone}
				backgroundImage="/backmobile.svg"
			/>
		)
	}

	return (
		<ThemeProvider theme={defaultTheme}>
			<Grid
				container
				sx={{
					height: '100vh',
					width: '100vw',
					backgroundColor: '#589ec3',
					backgroundSize: 'cover',
					backgroundPosition: 'center',
					display: 'flex',
					flexDirection: 'column',
					alignItems: 'center'
					// justifyContent: 'space-between',
				}}
			>
				{/* Logo */}
				<AppLogo
					code={appCode}
					setImagePath={setImagePath}
					setIsValidCode={setIsValidCode}
					imagePath={imagePath}
				/>

				{/* Perguntas */}
				<Grid
					item
					sx={{
						height: '50%',
						display: 'flex',
						width: '90%',
						flexDirection: 'column',
						alignItems: 'center',
						justifyContent: 'flex-start'
					}}
				>
					<LinearProgressFidelizap variant="determinate" value={80} />
					<Box
						sx={{
							paddingTop: 10,
							textAlign: 'center',
							maxWidth: '90%',
							marginBottom: 3
						}}
					>
						<Typography variant="h6" color="white" fontWeight="bold">
							Qual seu nome?
						</Typography>
					</Box>
					<TextField
						sx={{
							width: '90%',
							background: '#6cdbf4',
							borderRadius: '10px',
							input: {
								textAlign: 'center',
								color: 'white',
								padding: 2,
								fontWeight: 'bold'
							},
							borderBottom: '3px solid white'
						}}
						variant="filled"
						placeholder="DIGITE SEU PRIMEIRO NOME"
						InputProps={{
							disableUnderline: true,
							style: {
								textAlign: 'center'
							}
						}}
						error={erroName}
						FormHelperTextProps={{ classes: helperText }}
						helperText={erroName ? 'Informe seu nome' : ''}
						onChange={e => setName(e.target.value)}
					/>

					<Box
						sx={{
							paddingTop: 10,
							textAlign: 'center',
							maxWidth: '90%',
							marginBottom: 3
						}}
					>
						<Typography variant="h6" color="white" fontWeight="bold">
							Qual sua data de nascimento?
						</Typography>
					</Box>
					<TextField
						sx={{
							width: '90%',
							background: '#6cdbf4',
							borderRadius: '10px',
							input: {
								textAlign: 'center',
								color: 'white',
								padding: 2,
								fontWeight: 'bold'
							},
							borderBottom: '3px solid white'
						}}
						variant="filled"
						type="date"
						placeholder="28/02/1990"
						InputProps={{
							disableUnderline: true,
							style: {
								textAlign: 'center'
							}
						}}
						error={erroBirthDate}
						FormHelperTextProps={{ classes: helperText }}
						helperText={erroBirthDate ? 'Informe sua data de nascimento' : ''}
						onChange={e => setBirthDate(e.target.value)}
					/>

					<Button
						variant="contained"
						sx={{
							marginTop: 10,
							backgroundColor: '#A4FF78',
							color: 'black',
							fontWeight: 'bold',
							borderRadius: '20px',
							paddingX: 4
						}}
						onClick={goToDataValue}
					>
						Continuar
					</Button>
				</Grid>

				{/* Logo no rodapé */}
				<Grid
					item
					sx={{
						height: '25%',
						width: '100%',
						display: 'flex',
						alignItems: 'end',
						justifyContent: 'flex-end'
					}}
				>
					<Box
						component="img"
						src="/logo.svg"
						alt="Fidelizap"
						sx={{
							width: 100,
							marginBottom: 2,
							alignSelf: 'flex-end',
							marginRight: 2
						}}
					/>
				</Grid>
			</Grid>
		</ThemeProvider>
	)
}

export default ClientDataForm
