import { Box, Grid } from '@mui/material'
import { useCallback, useState } from 'react'
import { findAppAction } from '../actions/application.actions'
import Loading from './loading'

interface AppLogoProps {
	code: string
	imagePath?: string
	setImagePath: (imagePath: string) => void
	setIsValidCode: (isValidCode: boolean) => void
}

export function AppLogo({
	code,
	imagePath,
	setImagePath,
	setIsValidCode
}: AppLogoProps) {
	const getLogo = useCallback(async () => {
		try {
			const dataApplication = await findAppAction(code)
			if (dataApplication) {
				setImagePath(String(dataApplication.imagePath))
			}
		} catch (error) {
			console.error(error)
			setIsValidCode(false)
		}
	}, [imagePath, code])

	if (!code) {
		return null
	}

	if (!imagePath) {
		getLogo()
	}
	return (
		<Grid
			item
			sx={{
				height: '25%',
				display: 'flex',
				alignItems: 'center',
				justifyContent: 'center'
			}}
		>
			{imagePath ? (
				<Box
					component="img"
					src={imagePath}
					alt="Fidelizap"
					sx={{
						width: '80%'
					}}
				/>
			) : (
				<Loading />
			)}
		</Grid>
	)
}
