import { Box } from '@material-ui/core'
import { Button, createTheme, Grid, Typography } from '@mui/material'
import { ThemeProvider } from '@mui/styles'
import { useLocation, useNavigate } from 'react-router'
import { useSearchParams } from 'react-router-dom'
import { useCallback, useState } from 'react'
import { AppCodeNotFound, LinearProgressFidelizap } from '../../components'
import { AppLogo } from '../../components/logo-application'
import { Client } from '../../types/clients'

const defaultTheme = createTheme()

const buttons: { emoji: string; text: string }[] = [
	{ emoji: '😍', text: 'Muito sortudo' },
	{ emoji: '🤔', text: 'Mais ou menos' },
	{ emoji: '🥺', text: 'Pouco sortudo' }
]

function ClientQuiz() {
	const [searchParams, _setSearchParams] = useSearchParams()
	const appCode = searchParams.get('app_code')
	const cellphone = searchParams.get('cell')
	const location = useLocation()
	const navigate = useNavigate()
	const [isValidCode, setIsValidCode] = useState<boolean>(true)
	const { state: { imagePathState, client } = {} } = location

	const [imagePath, setImagePath] = useState<string | undefined>(imagePathState)

	const goToDataForm = useCallback(() => {
		const appCodeEncoded = encodeURIComponent(appCode || '')
		const cellphoneEncoded = encodeURIComponent(cellphone || '')
		const clientData = client as Client | undefined
		if (clientData) {
			navigate(
				`/client/data-value?app_code=${appCodeEncoded}&cell=${cellphoneEncoded}`,
				{
					state: {
						imagePathState: imagePath,
						name: clientData.name,
						birthDate: clientData.birthDate
					}
				}
			)
			return
		}
		navigate(
			`/client/data-form?app_code=${appCodeEncoded}&cell=${cellphoneEncoded}`,
			{
				state: {
					imagePathState: imagePath
				}
			}
		)
	}, [client, appCode, navigate, cellphone, imagePath])

	if (!appCode || !isValidCode || !cellphone) {
		return (
			<AppCodeNotFound
				code={appCode || ''}
				cellphone={cellphone}
				backgroundImage="/backmobile.svg"
			/>
		)
	}

	return (
		<ThemeProvider theme={defaultTheme}>
			<Grid
				container
				sx={{
					height: '100vh',
					width: '100vw',
					backgroundColor: '#589ec3',
					backgroundSize: 'cover',
					backgroundPosition: 'center',
					display: 'flex',
					flexDirection: 'column',
					alignItems: 'center'
					// justifyContent: 'space-between',
				}}
			>
				<AppLogo
					code={appCode}
					setImagePath={setImagePath}
					setIsValidCode={setIsValidCode}
					imagePath={imagePath}
				/>

				{/* Pergunta */}
				<Grid
					item
					sx={{
						height: '50%',
						width: '90%',
						display: 'flex',
						flexDirection: 'column',
						alignItems: 'center',
						justifyContent: 'flex-start'
					}}
				>
					<LinearProgressFidelizap variant="determinate" value={40} />
					<Box
						sx={{
							paddingY: 4,
							textAlign: 'center',
							maxWidth: '90%',
							marginBottom: 4
						}}
					>
						<Typography variant="h6" color="white" fontWeight="bold">
							Quão sortudo você está se sentindo hoje?
						</Typography>
					</Box>
					{/* Opções */}
					{buttons.map(b => (
						<Button
							key={b.text}
							variant="contained"
							sx={{
								backgroundColor: '#6cdbf4',
								color: 'white',
								fontWeight: 'bold',
								borderRadius: 4,
								paddingX: 4,
								marginBottom: 4,
								width: '80%',
								height: '50px',
								display: 'flex',
								alignItems: 'center',
								justifyContent: 'center',
								borderBottom: '2px solid #ffffff'
							}}
							onClick={goToDataForm}
							startIcon={<Box sx={{ paddingRight: 4 }}>{b.emoji}</Box>}
						>
							<Typography variant="h6" fontWeight="bold">
								{b.text}
							</Typography>
						</Button>
					))}
				</Grid>

				{/* Logo no rodapé */}
				<Grid
					item
					sx={{
						height: '25%',
						width: '100%',
						display: 'flex',
						alignItems: 'end',
						justifyContent: 'flex-end'
					}}
				>
					<Box
						component="img"
						src="/logo.svg"
						alt="Fidelizap"
						sx={{
							width: 100,
							marginBottom: 2,
							alignSelf: 'flex-end',
							marginRight: 2
						}}
					/>
				</Grid>
			</Grid>
		</ThemeProvider>
	)
}

export default ClientQuiz
