import { Box, Button, createTheme, Grid, Typography } from '@mui/material'
import { ThemeProvider } from '@mui/styles'
import { useNavigate, useSearchParams } from 'react-router-dom'
import { useCallback, useEffect, useState } from 'react'
import { toast } from 'react-toastify'
import AppCodeNotFound from '../../components/app-code-not-found'
import { AppLogo } from '../../components/logo-application'
import { existsCellPhoneAction } from '../../actions/clients.actions'
import Loading from '../../components/loading'
import { Client } from '../../types/clients'

const defaultTheme = createTheme()

function ClientWelcome() {
	const [searchParams, _setSearchParams] = useSearchParams()
	const [imagePath, setImagePath] = useState<string | undefined>(undefined)
	const [loading, setLoading] = useState(true)
	const [client, setClient] = useState<Client | null>(null)
	const [isValidCode, setIsValidCode] = useState<boolean>(true)
	const navigate = useNavigate()

	const appCode = searchParams.get('app_code')
	const cellphone = searchParams.get('cell')
	const searchClient = useCallback(async () => {
		try {
			const client = await existsCellPhoneAction(
				String(cellphone),
				String(appCode)
			)
			setClient(client)
		} catch (error) {
			toast.error('Erro ao buscar cliente')
		} finally {
			setLoading(false)
		}
	}, [cellphone, appCode])

	useEffect(() => {
		if (appCode && cellphone) {
			searchClient()
		}
	}, [appCode, cellphone, searchClient])

	const goToQuiz = useCallback(() => {
		const appCodeEncoded = encodeURIComponent(appCode || '')
		const cellphoneEncoded = encodeURIComponent(cellphone || '')
		navigate(
			`/client/quiz?app_code=${appCodeEncoded}&cell=${cellphoneEncoded}`,
			{
				state: {
					imagePathState: imagePath,
					client
				}
			}
		)
	}, [appCode, navigate, cellphone, imagePath, client])

	if (!appCode || !isValidCode || !cellphone) {
		return (
			<AppCodeNotFound
				code={appCode || ''}
				cellphone={cellphone}
				backgroundImage="/backmobile.svg"
			/>
		)
	}

	return (
		<ThemeProvider theme={defaultTheme}>
			<Grid
				container
				sx={{
					height: '100vh',
					width: '100vw',
					backgroundImage: 'url(/backmobile.svg)',
					backgroundSize: 'cover',
					backgroundPosition: 'center',
					display: 'flex',
					flexDirection: 'column',
					alignItems: 'center'
					// justifyContent: 'space-between',
				}}
			>
				<AppLogo
					code={appCode}
					setImagePath={setImagePath}
					setIsValidCode={setIsValidCode}
					imagePath={imagePath}
				/>

				{/* Mensagem de boas-vindas */}
				<Grid
					item
					sx={{
						height: '50%',
						display: 'flex',
						flexDirection: 'column',
						alignItems: 'center',
						justifyContent: 'flex-start'
					}}
				>
					<Box
						sx={{
							backgroundColor: 'rgba(0, 0, 0, 0.3)',
							padding: 2,
							borderRadius: 2,
							textAlign: 'center',
							maxWidth: '80%',
							marginBottom: 2
						}}
					>
						<Typography variant="h6" color="white" fontWeight="bold">
							Seja bem-vindo a nossa loja, espero que tenha a melhor experiência
						</Typography>
					</Box>
					{/* Botão */}
					<Button
						variant="contained"
						sx={{
							backgroundColor: '#A4FF78',
							color: 'black',
							fontWeight: 'bold',
							borderRadius: '20px',
							paddingX: 4
						}}
						disabled={loading}
						onClick={goToQuiz}
						startIcon={loading ? <Loading /> : undefined}
					>
						{loading ? 'Carregando...' : 'Começar'}
					</Button>
				</Grid>

				{/* Logo no rodapé */}
				<Grid
					item
					sx={{
						height: '25%',
						width: '100%',
						display: 'flex',
						alignItems: 'end',
						justifyContent: 'flex-end'
					}}
				>
					<Box
						component="img"
						src="/logo.svg"
						alt="Fidelizap"
						sx={{
							width: 100,
							marginBottom: 2,
							alignSelf: 'flex-end',
							marginRight: 2
						}}
					/>
				</Grid>
			</Grid>
		</ThemeProvider>
	)
}

export default ClientWelcome
