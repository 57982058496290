import { Box } from '@material-ui/core'
import { createTheme, Grid, Typography } from '@mui/material'
import { ThemeProvider } from '@mui/styles'
import { useCallback, useEffect, useState } from 'react'
import { useLocation, useNavigate } from 'react-router'
import { useSearchParams } from 'react-router-dom'
import { toast } from 'react-toastify'
import Loading from '../../components/loading'
import { AppLogo } from '../../components/logo-application'
import { AppCodeNotFound } from '../../components'
import { spinRouletteAction } from '../../actions/roulette'
import { dateStringToPtBr } from '../../utils'

const defaultTheme = createTheme()

function ClientLoading() {
	const location = useLocation()
	const [searchParams, _setSearchParams] = useSearchParams()
	const [isValidCode, setIsValidCode] = useState<boolean>(true)
	const [loading, setLoading] = useState(true)
	const [error, setError] = useState(false)
	const cellphone = searchParams.get('cell')
	const appCode = searchParams.get('app_code')
	const navigate = useNavigate()
	const { state: { imagePathState, name, birthDate, value } = {} } = location
	const [imagePath, setImagePath] = useState<string | undefined>(imagePathState)

	const sendRequest = useCallback(async () => {
		try {
			const data = (await spinRouletteAction({
				code: appCode || '',
				cellphoneNumber: cellphone || '',
				name: name || '',
				dataN: dateStringToPtBr(birthDate || ''),
				value: Number(value),
				email: null
			})) as any
			if (data.type === 'PREMIO_PENDENTE') {
				toast.error(
					'Você já possui um prêmio pendente, por favor retire-o antes de solicitar outro.'
				)
				return
			}
			const appCodeEncoded = encodeURIComponent(String(appCode))
			const cellphoneEncoded = encodeURIComponent(cellphone || '')
			setLoading(false)
			navigate(
				`/client/dashboard?app_code=${appCodeEncoded}&cell=${cellphoneEncoded}`
			)
		} catch (error) {
			toast.error('Erro ao enviar solicitação')
			setError(true)
			setLoading(false)
		}
	}, [appCode, cellphone, name, birthDate, value])

	useEffect(() => {
		if (appCode && cellphone) {
			sendRequest()
		}
	}, [setLoading])
	if (!appCode || !cellphone || !isValidCode) {
		return (
			<AppCodeNotFound
				code={appCode || ''}
				cellphone={cellphone}
				backgroundImage="/backmobile.svg"
			/>
		)
	}
	return (
		<ThemeProvider theme={defaultTheme}>
			<Grid
				container
				sx={{
					height: '100vh',
					width: '100vw',
					backgroundColor: '#589ec3',
					backgroundSize: 'cover',
					backgroundPosition: 'center',
					display: 'flex',
					flexDirection: 'column',
					alignItems: 'center'
				}}
			>
				{/* Logo */}
				<AppLogo
					code={appCode}
					setImagePath={setImagePath}
					setIsValidCode={setIsValidCode}
					imagePath={imagePath}
				/>

				{/* Pergunta */}
				<Grid
					item
					sx={{
						height: '50%',
						width: '90%',
						display: 'flex',
						flexDirection: 'column',
						alignItems: 'center',
						justifyContent: 'flex-start'
					}}
				>
					<Box
						sx={{
							paddingY: 4,
							textAlign: 'center',
							maxWidth: '90%',
							marginBottom: 4
						}}
					>
						{loading || error ? (
							<Typography variant="h6" color="white" fontWeight="bold">
								Estamos enviando sua solicitação...
							</Typography>
						) : (
							<Typography variant="h5" color="white" fontWeight="bold">
								Aguarde até um atendentente aprovar 😍
							</Typography>
						)}
					</Box>
					{loading ? <Loading /> : <Loading size={150} />}
				</Grid>

				{/* Logo no rodapé */}
				<Grid
					item
					sx={{
						height: '25%',
						width: '100%',
						display: 'flex',
						alignItems: 'end',
						justifyContent: 'flex-end'
					}}
				>
					<Box
						component="img"
						src="/logo.svg"
						alt="Fidelizap"
						sx={{
							width: 100,
							marginBottom: 2,
							alignSelf: 'flex-end',
							marginRight: 2
						}}
					/>
				</Grid>
			</Grid>
		</ThemeProvider>
	)
}

export default ClientLoading
