import { LinearProgress, linearProgressClasses } from '@mui/material'
import { styled } from '@mui/material/styles'

const LinearProgressFidelizap = styled(LinearProgress)(() => ({
	height: 15,
	width: '90%',
	borderRadius: 7,
	[`&.${linearProgressClasses.colorPrimary}`]: {
		backgroundColor: '#171717'
	},
	[`& .${linearProgressClasses.bar}`]: {
		borderRadius: 5,
		backgroundColor: '#b1fc54'
	}
}))

export default LinearProgressFidelizap
