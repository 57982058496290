import { Box, createTheme, Grid } from '@mui/material'
import { ThemeProvider } from '@mui/styles'

type AppCodeNotFoundProps = {
	code: string
	cellphone: string | null
	backgroundImage: string
}

const defaultTheme = createTheme()

function AppInvalid({ code }: { code: string }) {
	return (
		<Grid
			item
			sx={{
				display: 'flex',
				flexDirection: 'column',
				alignItems: 'center',
				justifyContent: 'flex-start',
				maxWidth: '80%',
				backgroundColor: 'rgba(0, 0, 0, 0.3)',
				padding: 2,
				borderRadius: 2
			}}
		>
			<Box
				sx={{
					fontSize: '2rem',
					fontWeight: 'bold',
					color: 'white',
					marginBottom: '1rem',
					textAlign: 'center'
				}}
			>
				Código de aplicativo inválido
			</Box>
			<Box
				sx={{
					fontSize: '1.5rem',
					color: 'white',
					textAlign: 'justify'
				}}
			>
				Sinto muito, mas o código <strong>{code}</strong> não corresponde a uma
				empresa ativa em nossa base de dados.
			</Box>
		</Grid>
	)
}

function CellPhoneInvalid({ cellphone }: { cellphone: string }) {
	return (
		<Grid
			item
			sx={{
				display: 'flex',
				flexDirection: 'column',
				alignItems: 'center',
				justifyContent: 'flex-start',
				maxWidth: '80%',
				backgroundColor: 'rgba(0, 0, 0, 0.3)',
				padding: 2,
				borderRadius: 2
			}}
		>
			<Box
				sx={{
					fontSize: '2rem',
					fontWeight: 'bold',
					color: 'white',
					marginBottom: '1rem',
					textAlign: 'center'
				}}
			>
				Celular inválido
			</Box>
			<Box
				sx={{
					fontSize: '1.5rem',
					color: 'white',
					textAlign: 'justify'
				}}
			>
				Desculpe mas não conseguimos identificar o número de celular{' '}
				<strong>{cellphone}</strong> como um número válido.
			</Box>
		</Grid>
	)
}

function AppCodeNotFound({
	backgroundImage,
	code,
	cellphone
}: AppCodeNotFoundProps) {
	return (
		<ThemeProvider theme={defaultTheme}>
			<Grid
				container
				sx={{
					height: '100vh',
					width: '100vw',
					backgroundImage: `url(${backgroundImage})`,
					backgroundSize: 'cover',
					backgroundPosition: 'center',
					display: 'flex',
					flexDirection: 'column',
					alignItems: 'center'
					// justifyContent: 'space-between',
				}}
			>
				{/* Logo */}
				<Grid
					item
					sx={{
						height: '25%',
						display: 'flex',
						alignItems: 'center',
						justifyContent: 'center'
					}}
				>
					<Box
						component="img"
						src="/logo.svg"
						alt="Fidelizap"
						sx={{
							width: '80%'
						}}
					/>
				</Grid>
				{/* Mensagem de erro */}
				{!cellphone ? (
					<CellPhoneInvalid cellphone={cellphone || ''} />
				) : (
					<AppInvalid code={code} />
				)}
			</Grid>
		</ThemeProvider>
	)
}
export default AppCodeNotFound
