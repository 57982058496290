import React, { Context, createContext } from 'react'
import { User } from '../types/user'
import {
	saveApplicationInfoAction,
	uploadLogoAction
} from '../actions/application.actions'
import { loginAction, registerAction } from '../actions'
import { Application, SaveApplicationInfo } from '../types/application'

interface AuthContextProps {
	user: User | null
	application: Application | null
	imageApplication: string | null
	login: (credentials: LoginCredentials) => Promise<void>
	register: (credentials: RegisterCredentials) => Promise<void>
	updateApplicationInfo: (data: SaveApplicationInfo) => Promise<Application>
	uploadLogo: (file: File) => Promise<void>
	setApplication: (application: Application) => void
	logout: () => void
	isLogged: () => boolean
}

interface LoginCredentials {
	email: string
	password: string
}

interface RegisterCredentials {
	email: string
	password: string
	name: string
	code: string
}

export const AuthContext = createContext({} as AuthContextProps)

export function AuthProvider({ children }: React.PropsWithChildren<{}>) {
	const [user, setUser] = React.useState<User | null>(() => {
		const token = localStorage.getItem('token')
		if (token) {
			return { token } as User
		}
		return null
	})
	const [application, setApplication] = React.useState<Application | null>(
		() => {
			const application = localStorage.getItem('application')
			if (application) {
				return JSON.parse(application)
			}
			return null
		}
	)
	const [imageApplication, setImageApplication] = React.useState<string>(() => {
		const image = localStorage.getItem('image')
		if (image) {
			return image
		}
		return ''
	})

	const login = async ({ email, password }: LoginCredentials) => {
		const { token, imageApplication, application } = await loginAction(
			email,
			password
		)
		setUser({ token } as User)
		setApplication(application)
		setImageApplication(imageApplication)
		localStorage.setItem('token', token)
		localStorage.setItem('application', JSON.stringify(application))
		localStorage.setItem('image', imageApplication)
	}

	const uploadLogo = async (file: File) => {
		const { fileName } = await uploadLogoAction(file)
		localStorage.setItem('image', fileName)
		setImageApplication(fileName)
	}

	const updateApplicationInfo = async (data: SaveApplicationInfo) => {
		const application = await saveApplicationInfoAction(data)
		setApplication(application)
		localStorage.setItem('application', JSON.stringify(application))
		return application
	}

	const register = async ({
		email,
		password,
		name,
		code
	}: RegisterCredentials) => {
		const { token, imageApplication, application } = await registerAction(
			email,
			password,
			name,
			code
		)
		setUser({ token } as User)
		setImageApplication(imageApplication)
		setApplication(application)
		localStorage.setItem('token', token)
		localStorage.setItem('application', JSON.stringify(application))
		localStorage.setItem('image', imageApplication)
	}

	const logout = () => {
		setUser(null)
		localStorage.removeItem('token')
		localStorage.removeItem('image')
		localStorage.removeItem('application')
		localStorage.removeItem('defaultPage')
	}

	const isLogged = () => {
		const session = localStorage.getItem('token')
		if (session) {
			return true
		}
		return false
	}
	const authContextValue = React.useMemo(
		() => ({
			user,
			application,
			imageApplication,
			login,
			logout,
			updateApplicationInfo,
			register,
			uploadLogo,
			setApplication,
			isLogged
		}),
		[user, login, logout, register, uploadLogo, isLogged, setApplication]
	)

	return (
		<AuthContext.Provider value={authContextValue}>
			{children}
		</AuthContext.Provider>
	)
}

export const useAuth = () => {
	const context = React.useContext<AuthContextProps>(
		AuthContext as Context<AuthContextProps>
	)
	if (!context) throw new Error('AuthProvider is required')

	return context
}
