import {
	Box,
	Grid,
	SpeedDial,
	SpeedDialAction,
	SpeedDialIcon,
	Typography
} from '@mui/material'
import { Wheel } from 'react-custom-roulette'
import { memo, useCallback, useContext, useEffect, useState } from 'react'
import ReactConfetti from 'react-confetti'
import { useNavigate } from 'react-router'
import DashboardIcon from '@mui/icons-material/Dashboard'
import { useSearchParams } from 'react-router-dom'
import { RouletteOptions } from '../../types/roulette'
import ModalConfig from './modal-config/modal'
import {
	getOptionsRouletteQueryAction,
	saveOptionsRouletteAction
} from '../../actions/roulette-options'
import { SocketContext } from '../../context/socket'
import { SocketModule } from '../../enums/socket'
import DialogAward from './dialog/award'
import { Spin } from '../../types/spin'
import ExportModal from './export-modal/modal'
import { AppCodeNotFound } from '../../components'
import { findAppAction } from '../../actions/application.actions'
import { useAuth } from '../../hooks'

type Award = {
	awardName: string
	clientName: string
	prizeNumber: number
}

type RouletteData = {
	option: string
}

type RouletteProps = {
	data: { option: string }[]
	mustStartSpinning: boolean
	prizeNumber: number
	onStopSpinning: () => void
	backgroundColors: string[]
}

const Roulette = memo((props: RouletteProps) => (
	<Wheel {...props} textColors={['#ffffff']} disableInitialAnimation />
))

function ClientDashBoard() {
	const emptyAward = {
		awardName: '',
		clientName: '',
		prizeNumber: 0
	}

	const navigate = useNavigate()
	const { application, setApplication } = useAuth()
	const [searchParams, _setSearchParams] = useSearchParams()
	const cellphone = searchParams.get('cell')
	const appCode = searchParams.get('app_code')
	// ['#3e3e3e', '#df3428']
	const { message, connectionStatus } = useContext(SocketContext)
	const [optionsRoulette, setOptionsRoulette] = useState<RouletteOptions[]>([])
	const [options, setOptions] = useState<RouletteData[]>([])
	const [colors, setColors] = useState<string[]>([])

	const [showAward, setShowAward] = useState(false)
	const [award, setAward] = useState<Award>(emptyAward)
	const [initialConnection, setInitialConnection] = useState(false)
	const [errorSocket, setErrorSocket] = useState(false)
	console.log('errorSocket:', errorSocket)
	const [loading, setLoading] = useState(true)
	const [awardQueue, setAwardQueue] = useState<Spin[]>([])

	const [mustSpin, setMustSpin] = useState(false)
	const [openModal, setOpenModal] = useState(false)
	const [openModalExport, setOpenModalExport] = useState(false)
	const [history, setHistory] = useState<Spin[]>([])

	const handleMessageRouletteOptions = useCallback(
		(data: RouletteOptions[]) => {
			setOptionsRoulette(data)
		},
		[setOptionsRoulette]
	)

	const handleMessageSpin = useCallback(
		(data: Spin) => {
			if (mustSpin || showAward) {
				setAwardQueue([...awardQueue, data])
				setHistory([data, ...history])
				return
			}
			setAward({
				awardName: data.selectedOption.name,
				clientName: data.name,
				prizeNumber: data.selectedOptionIndex
			})
			setMustSpin(true)
			setHistory([data, ...history])
		},
		[history, mustSpin, showAward, award, setHistory, setAward, setMustSpin]
	)

	const clearAward = useCallback(() => {
		setAward(emptyAward)
	}, [setAward])

	const nextQueueSpin = useCallback(() => {
		if (awardQueue.length) {
			const [spin, ...rest] = awardQueue
			setAwardQueue(rest)
			setAward({
				awardName: spin.selectedOption.name,
				clientName: spin.name,
				prizeNumber: spin.selectedOptionIndex
			})
			setMustSpin(true)
		}
	}, [awardQueue, setAward, setMustSpin])

	useEffect(() => {
		if (!message) {
			return
		}
		const { data } = message
		if (!data) {
			return
		}
		if (data.includes(SocketModule.ROULETTE_OPTIONS)) {
			const value = JSON.parse(data)
			handleMessageRouletteOptions(value.data)
		}
		if (data.includes(SocketModule.SPIN)) {
			const value = JSON.parse(data)
			if (value.data?.cellphoneNumber === cellphone) {
				handleMessageSpin(value.data)
			}
		}
	}, [message])

	useEffect(() => {
		setOptions(optionsRoulette.map(item => ({ option: item.name })))
		setColors(optionsRoulette.map(item => item.color))
		if (optionsRoulette.length && loading) {
			setLoading(false)
		}
	}, [optionsRoulette, loading])

	useEffect(() => {
		if (connectionStatus === 'Open') {
			setInitialConnection(true)
			setErrorSocket(false)
			return
		}
		if (connectionStatus === 'Connecting' && !initialConnection) {
			setErrorSocket(false)
			return
		}
		if (connectionStatus === 'Uninstantiated') {
			setErrorSocket(false)
			return
		}
		setErrorSocket(true)
	}, [connectionStatus])

	const fetchApplication = useCallback(async () => {
		if (!appCode) {
			return
		}
		const app = await findAppAction(appCode)
		setApplication(app)
	}, [setApplication])

	const fetchOptionsRoulette = useCallback(async () => {
		try {
			if (!application) {
				return
			}
			const options = await getOptionsRouletteQueryAction(application.id)
			setOptionsRoulette(options)
		} catch (error) {
			console.error(error)
		} finally {
			setLoading(false)
		}
	}, [application])

	useEffect(() => {
		if (!application) {
			return
		}
		fetchOptionsRoulette()
	}, [application])

	useEffect(() => {
		fetchApplication()
		// fetchHistoryRoulette()
	}, [])

	const handleGoToPanel = useCallback(() => {
		navigate('/panel/metrics')
	}, [])

	const saveOptions = useCallback(async (items: Partial<RouletteOptions>[]) => {
		setLoading(true)
		await saveOptionsRouletteAction(items)
	}, [])

	const handleStopSpinning = useCallback(() => {
		setMustSpin(false)
		setShowAward(true)
	}, [setMustSpin, setShowAward])

	if (!appCode && !cellphone) {
		return (
			<AppCodeNotFound
				code={appCode || ''}
				cellphone={cellphone}
				backgroundImage="/backmobile.svg"
			/>
		)
	}

	return (
		<Grid
			container
			sx={{
				backgroundImage:
					'linear-gradient(45deg, #030a14, #030a14, #030a14, #030a14, #111c2e, #111c2e, #111c2e, #1e1e1e, #1e1e1e)',
				height: '100vh'
			}}
		>
			<Grid item xs={12}>
				<Box
					sx={{
						display: 'flex',
						justifyContent: 'center',
						p: 2,
						maxHeight: '10vh',
						flex: 1,
						marginBottom: 2
					}}
				>
					{application?.imagePath && (
						<img
							srcSet={application?.imagePath || ''}
							src={application?.imagePath || ''}
							alt="Logo"
							loading="lazy"
							style={{
								height: '10vh'
							}}
						/>
					)}
				</Box>
				<Grid
					container
					spacing={2}
					sx={{
						display: 'flex',
						alignItems: 'center',
						justifyContent: 'center',
						flex: 1
					}}
				>
					<Grid item xs={2} />
					<Grid
						item
						xs={8}
						sx={{
							position: 'relative',
							display: 'flex',
							justifyContent: 'center',
							alignItems: 'center',
							marginTop: 2
						}}
					>
						<Typography
							variant="h2"
							sx={{
								textAlign: 'center',
								color: '#ffffff'
							}}
						>
							{application?.rouletteMessage}
						</Typography>
					</Grid>
					<Grid
						item
						xs={2}
						sx={{
							paddingRight: 2,
							display: 'flex',
							justifyContent: 'end',
							alignItems: 'end'
						}}
					/>
					<SpeedDial
						ariaLabel="Opções"
						sx={{
							position: 'absolute',
							bottom: 16,
							right: 16,
							display: application ? undefined : 'none'
						}}
						icon={<SpeedDialIcon />}
					>
						<SpeedDialAction
							key="Painel"
							icon={<DashboardIcon />}
							tooltipTitle="Painel"
							onClick={handleGoToPanel}
						/>
						{/* <SpeedDialAction
                            key="Configurações"
                            icon={<SettingsApplications />}
                            tooltipTitle="Configurações"
                            onClick={() => {
                                setOpenModal(true)
                            }}
                        /> */}
						{/* <SpeedDialAction
                            key="Exportar Clientes"
                            icon={<GetApp />}
                            tooltipTitle="Exportar Clientes"
                            onClick={() => {
                                setOpenModalExport(true)
                            }}
                        /> */}
						{/* <SpeedDialAction
                            key="Histórico"
                            icon={<History />}
                            tooltipTitle="Histórico"
                            onClick={() => {
                                setShowHistory(!showHistory)
                            }}
                        /> */}
					</SpeedDial>
				</Grid>
				{errorSocket && (
					<Typography
						variant="h6"
						sx={{
							textAlign: 'center',
							color: 'red'
						}}
					>
						Erro na conexão com o servidor
					</Typography>
				)}
				<Box
					sx={{
						display: 'flex',
						justifyContent: 'right',
						p: 2
					}}
				>
					<ModalConfig
						isOpen={openModal}
						options={optionsRoulette}
						onClose={() => {
							setOpenModal(false)
						}}
						onSaved={saveOptions}
					/>
					<ExportModal
						open={openModalExport}
						onClose={() => setOpenModalExport(false)}
					/>
				</Box>
				<Box
					sx={{
						display: 'flex',
						justifyContent: 'right',
						p: 2
					}}
				/>
				<Box
					sx={{
						display: 'flex',
						justifyContent: 'center',
						p: 2
					}}
				>
					{loading ? (
						<Typography variant="h6">Carregando...</Typography>
					) : options.length ? (
						<Grid>
							<Grid container>
								<Roulette
									mustStartSpinning={mustSpin}
									prizeNumber={award.prizeNumber}
									data={options}
									backgroundColors={colors}
									onStopSpinning={handleStopSpinning}
								/>
							</Grid>
						</Grid>
					) : (
						<Typography variant="h6">Nenhuma opção cadastrada</Typography>
					)}
				</Box>
				<Grid
					container
					sx={{
						position: 'absolute',
						bottom: 0,
						width: '100%',
						display: 'flex'
					}}
				>
					<img
						srcSet="/logo_felidelizap_branca.svg"
						src="/logo_felidelizap_branca.svg"
						alt="Logo"
						loading="lazy"
						style={{
							height: '7vh',
							paddingLeft: 10
						}}
					/>
				</Grid>
			</Grid>

			{showAward && (
				<Grid>
					<ReactConfetti run={showAward} />
					<DialogAward
						handleClose={() => {
							setShowAward(false)
							clearAward()
							setTimeout(() => {
								nextQueueSpin()
							}, 500)
						}}
						awardName={award.awardName}
						open={showAward}
						clientName={award.clientName}
					/>
				</Grid>
			)}
		</Grid>
	)
}

export default ClientDashBoard
