import { HTTP } from '../providers'

export class ClientService extends HTTP {
	async export(type: string) {
		return this.get({
			endpoint: `/clients/export`,
			params: { type },
			responseType: 'blob'
		})
	}

	async getClients() {
		return this.get({ endpoint: '/clients' })
	}

	async getClientInfo(id: number) {
		return this.get({ endpoint: `/clients/${id}` })
	}

	async existsCellPhone(cellphone: string, appCode: string) {
		const encodedCellphone = encodeURIComponent(cellphone)
		const encodedAppCode = encodeURIComponent(appCode)
		return this.get({
			endpoint: `/clients/exists?cellphone=${encodedCellphone}&app_code=${encodedAppCode}`
		})
	}
}
